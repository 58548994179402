import React from "react"
import { graphql } from "gatsby"
import { saveAs } from "file-saver"
import axios from "axios"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { nodeServer } from "../../request"

// const fmtDate = dateStr => {
//   const date = new Date(dateStr)
//   let mm = date.getMonth() + 1
//   mm = (mm > 9 ? "" : "0") + mm
//   return `${date.getFullYear() - 1911}.${mm}`
// }

const handleError = e => {
  console.error(e)
  alert("驗證伺服器異常")
}

class DownloadPage extends React.Component {
  state = {
    progress: 0,
  }

  openLink = link => {
    if (link.fileName) {
      axios({
        url: link.url,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: evt => {
          const progress =
            evt.total === 0 ? 100 : Math.floor((evt.loaded / evt.total) * 100)
          this.setState({
            progress,
          })
        },
      })
        .then(res => {
          if (res.data.error) return alert(res.data.error)
          saveAs(res.data, link.fileName)
        })
        .catch(handleError)
    } else {
      window.open(link.url, "_blank")
    }
  }

  open = (newsId, linkId) => {
    const serverUrl = `${nodeServer}/links/${linkId}?newsId=${newsId}`

    axios
      .get(serverUrl)
      .then(res => {
        if (res.data.error) return alert(res.data.error)
        if (res.data.password) {
          const password = prompt("請輸入開啟連結所需的驗證碼")
          if (!password) return
          axios
            .post(serverUrl, { password })
            .then(res => {
              if (res.data.error) return alert(res.data.error)
              this.openLink(res.data)
            })
            .catch(handleError)
        } else {
          this.openLink(res.data)
        }
      })
      .catch(handleError)
  }

  render() {
    const { data } = this.props
    const { progress } = this.state
    return (
      <Layout>
        <SEO title="Download" />
        <div className="download">
          {progress !== 0 && progress !== 100 && (
            <div className="download__progress">{`${progress}%`}</div>
          )}
          <table className="download__table">
            <thead>
              <tr>
                <th>內容描述</th>
                <th>檔案連結</th>
              </tr>
            </thead>
            <tbody>
              {data.allStrapiNews.edges.length ? (
                data.allStrapiNews.edges.map(({ node }) => (
                  <tr key={node.id}>
                    <td>{node.content}</td>
                    <td>
                      {node.links.map(link => (
                        <a
                          key={link.id}
                          href="#"
                          onClick={() => this.open(node.strapiId, link.id)}
                        >
                          {link.name}
                          &nbsp;
                        </a>
                      ))}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="2" style={{ textAlign: "center" }}>
                    無檔案
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    )
  }
}

export default DownloadPage

export const query = graphql`
  query {
    allStrapiNews(
      filter: { category: { eq: "download" } }
      sort: { fields: [publishDate], order: [DESC] }
    ) {
      edges {
        node {
          strapiId
          content
          publishDate
          links {
            name
            id
          }
        }
      }
    }
  }
`
